<template>
  <div>
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="false"
      color="#4285f4"
      loader="dots"
    >
    </loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";

export default {
  props: ["isLoading"],
  components: {
    Loading,
  },
  methods: {
    onCancel() {
      store.commit("appConfig/SPINNER_CONFIG", false);
      console.log("User cancelled the loader.");
    },
  },
};
</script>
