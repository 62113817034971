<script>
import XIcon from "vue-feather-icons/icons/XIcon";
import ChevronLeftIcon from "vue-feather-icons/icons/ChevronLeftIcon";
import ChevronRightIcon from "vue-feather-icons/icons/ChevronRightIcon";
import CheckCircleIcon from "vue-feather-icons/icons/CheckCircleIcon";
import ChevronDownIcon from "vue-feather-icons/icons/ChevronDownIcon";
import RotateCwIcon from "vue-feather-icons/icons/RotateCwIcon";
import ArrowUpIcon from "vue-feather-icons/icons/ArrowUpIcon";
import HomeIcon from "vue-feather-icons/icons/HomeIcon";
import SettingsIcon from "vue-feather-icons/icons/SettingsIcon";
import MenuIcon from "vue-feather-icons/icons/MenuIcon";
import BellIcon from "vue-feather-icons/icons/BellIcon";
import UserIcon from "vue-feather-icons/icons/UserIcon";
import BookIcon from "vue-feather-icons/icons/BookIcon";
import LogOutIcon from "vue-feather-icons/icons/LogOutIcon";
import InfoIcon from "vue-feather-icons/icons/InfoIcon";
import AlertTriangleIcon from "vue-feather-icons/icons/AlertTriangleIcon";
import EditIcon from "vue-feather-icons/icons/EditIcon";
import TrashIcon from "vue-feather-icons/icons/TrashIcon";
import Trash2Icon from "vue-feather-icons/icons/Trash2Icon";
import MoreVerticalIcon from "vue-feather-icons/icons/MoreVerticalIcon";
import FileTextIcon from "vue-feather-icons/icons/FileTextIcon";
import FileIcon from "vue-feather-icons/icons/FileIcon";
import LinkIcon from "vue-feather-icons/icons/LinkIcon";
import XCircleIcon from "vue-feather-icons/icons/XCircleIcon";
import LockIcon from "vue-feather-icons/icons/LockIcon";
import FacebookIcon from "vue-feather-icons/icons/FacebookIcon";
import TwitterIcon from "vue-feather-icons/icons/TwitterIcon";
import GithubIcon from "vue-feather-icons/icons/GithubIcon";
import MailIcon from "vue-feather-icons/icons/MailIcon";
import AlignJustifyIcon from "vue-feather-icons/icons/AlignJustifyIcon";
import ImageIcon from "vue-feather-icons/icons/ImageIcon";
import HeartIcon from "vue-feather-icons/icons/HeartIcon";
import MessageSquareIcon from "vue-feather-icons/icons/MessageSquareIcon";
import Share2Icon from "vue-feather-icons/icons/Share2Icon";
import UserPlusIcon from "vue-feather-icons/icons/UserPlusIcon";
import PackageIcon from "vue-feather-icons/icons/PackageIcon";
import UsersIcon from "vue-feather-icons/icons/UsersIcon";
import BriefcaseIcon from "vue-feather-icons/icons/BriefcaseIcon";
import BoxIcon from "vue-feather-icons/icons/BoxIcon";
import ClipboardIcon from "vue-feather-icons/icons/ClipboardIcon";
import MoonIcon from "vue-feather-icons/icons/MoonIcon";
import SunIcon from "vue-feather-icons/icons/SunIcon";
import EyeIcon from "vue-feather-icons/icons/EyeIcon";
import EyeOffIcon from "vue-feather-icons/icons/EyeOffIcon";
import CheckIcon from "vue-feather-icons/icons/CheckIcon";
import PlusIcon from "vue-feather-icons/icons/PlusIcon";
import MinusIcon from "vue-feather-icons/icons/MinusIcon";
import SendIcon from "vue-feather-icons/icons/SendIcon";
import PhoneIcon from "vue-feather-icons/icons/PhoneIcon";
import TrendingDownIcon from "vue-feather-icons/icons/TrendingDownIcon";
import TrendingUpIcon from "vue-feather-icons/icons/TrendingUpIcon";
import CircleIcon from "vue-feather-icons/icons/CircleIcon";
import ShieldIcon from "vue-feather-icons/icons/ShieldIcon";
import HeadphonesIcon from "vue-feather-icons/icons/HeadphonesIcon";
import MonitorIcon from "vue-feather-icons/icons/MonitorIcon";
import PercentIcon from "vue-feather-icons/icons/PercentIcon";

const icons = {
  XIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  RotateCwIcon,
  ArrowUpIcon,
  HomeIcon,
  SettingsIcon,
  MenuIcon,
  BellIcon,
  UserIcon,
  BookIcon,
  LogOutIcon,
  InfoIcon,
  AlertTriangleIcon,
  EditIcon,
  TrashIcon,
  Trash2Icon,
  MoreVerticalIcon,
  FileTextIcon,
  FileIcon,
  LinkIcon,
  XCircleIcon,
  LockIcon,
  FacebookIcon,
  TwitterIcon,
  GithubIcon,
  MailIcon,
  AlignJustifyIcon,
  ImageIcon,
  HeartIcon,
  MessageSquareIcon,
  Share2Icon,
  UserPlusIcon,
  PackageIcon,
  UsersIcon,
  BriefcaseIcon,
  BoxIcon,
  ClipboardIcon,
  MoonIcon,
  SunIcon,
  EyeIcon,
  EyeOffIcon,
  CheckIcon,
  PlusIcon,
  MinusIcon,
  SendIcon,
  PhoneIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  CircleIcon,
  ShieldIcon,
  HeadphonesIcon,
  MonitorIcon,
  PercentIcon,
};

export default {
  name: "FeatherIcon",
  functional: true,
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: String,
      default: "14",
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: "badge-primary",
    },
  },
  render(h, { props, data }) {
    // Create Feather Icon
    const svg = h(icons[props.icon], { props: { size: props.size }, ...data });

    // If no badge is provided => Render just SVG
    if (!props.badge) return svg;

    // Badge
    const badgeEl = h(
      "span",
      { staticClass: "badge badge-up badge-pill", class: props.badgeClasses },
      [props.badge]
    );

    // Return span with children
    return h("span", { staticClass: "feather-icon position-relative" }, [
      svg,
      badgeEl,
    ]);
  },
};
</script>

<style lang="scss">
svg.feather {
  outline: none;
}
</style>
