export default [
  {
    path: "/dashboard",
    name: "dashboard-usa",
    component: () => import("@/views/dashboard/usa/USA.vue"),
  },
  // {
  //   path: "/dashboard/emea",
  //   name: "dashboard-emea",
  //   component: () => import("@/views/dashboard/emea/EMEA.vue"),
  // },
  {
    path: "/dashboard/usa/incidents",
    name: "dashboard-usa-incidents",
    component: () => import("@/views/dashboard/usa/UsaGcpIncidents.vue"),
  },
];
