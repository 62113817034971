export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/apps/users/view/:id",
    name: "apps-users-view",
    component: () => import("@/views/apps/user/users-view/UsersView.vue"),
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },
  // *===============================================---*
  // *--------- ORGANIZATION ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/organizations/list",
    name: "apps-organizations-list",
    component: () =>
      import(
        "@/views/apps/organization/organizations-list/OrganizationsList.vue"
      ),
  },
  {
    path: "/apps/organizations/view/:id",
    name: "apps-organizations-view",
    component: () =>
      import(
        "@/views/apps/organization/organizations-view/OrganizationView.vue"
      ),
  },
  {
    path: "/apps/organizations/edit/:id",
    name: "apps-organizations-edit",
    component: () =>
      import(
        "@/views/apps/organization/organizations-edit/OrganizationEdit.vue"
      ),
  },
  // *===============================================---*
  // *--------- SUPPORTCASES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/support-cases/list",
    name: "apps-support-cases-list",
    component: () =>
      import(
        "@/views/apps/support-cases/support-cases-list/SupportCasesList.vue"
      ),
  },
  {
    path: "/apps/support-cases/open-ticket",
    name: "apps-support-cases-open-ticket",
    component: () =>
      import(
        "@/views/apps/support-cases/support-cases-list/SupportCasesList.vue"
      ),
  },
  {
    path: "/apps/support-cases/view/:id",
    name: "apps-support-cases-view",
    component: () =>
      import(
        "@/views/apps/support-cases/support-cases-view/SupportCasesView.vue"
      ),
  },
  {
    path: "/apps/support-cases/edit/:id",
    name: "apps-support-cases-edit",
    component: () =>
      import(
        "@/views/apps/support-cases/support-cases-edit/SupportCasesEdit.vue"
      ),
  },
  // *===============================================---*
  // *--------- PRODUCTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/gcp-helper/list",
    name: "apps-gcp-helpers-list",
    component: () =>
      import("@/views/apps/gcp-helper/gcp-helpers-list/GcpHelpersList.vue"),
  },
  // *===============================================---*
  // *--------- DISCOUNTSREPORT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/discounts-report",
    name: "apps-discounts-report-list",
    component: () =>
      import(
        "@/views/apps/discounts-report/DiscountsReport.vue"
      ),
  },
 // *===============================================---*
  // *--------- PARTNERDASHBOARD ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/partner",
    name: "dashboard-partner",
    component: () => import("@/views/apps/partner/Partner.vue"),
  },
    // *===============================================---*
  // *--------- ANOMALIESREPORT ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: "/apps/anomalies-report",
  //   name: "apps-anomalies-report-list",
  //   component: () =>
  //     import(
  //       "@/views/apps/anomalies-report/AnomaliesReport.vue"
  //     ),
  // },
   // *===============================================---*
  // *--------- SCOUTSUITE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/security-scan",
    name: "app-scoutsuite",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteTable.vue"),
  },
  {
    path: "/apps/security-scan/:id",
    name: "app-scoutsuite-list",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteList.vue"),
  },
  {
    path: "/apps/security-scan/:projectid/:id",
    name: "apps-scoutsuite-view",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteView.vue"),
  },
  {
    path: "/apps/security-scan/:projectid/:servicesid/:id",
    name: "apps-scoutsuite-role",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteRole.vue"),
  },
   // *===============================================---*
  // *--------- ChatGPT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chatbot",
    name: "apps-chatgpt",
    component: () =>
      import(
        "@/views/apps/chatgpt/ChatGpt.vue"
      ),
  },
   // *===============================================---*
  // *--------- Overview ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/overview",
    name: "apps-overview",
    component: () =>
      import(
        "@/views/apps/overview/Overview.vue"
      ),
  },
  // *===============================================---*
  // *--------- DISCOUNTSREPORT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/discounts-report",
    name: "apps-discounts-report-list",
    component: () =>
      import(
        "@/views/apps/discounts-report/DiscountsReport.vue"
      ),
  },
 // *===============================================---*
  // *--------- PARTNERDASHBOARD ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/partner",
    name: "dashboard-partner",
    component: () => import("@/views/apps/partner/Partner.vue"),
  },
    // *===============================================---*
  // *--------- ANOMALIESREPORT ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: "/apps/anomalies-report",
  //   name: "apps-anomalies-report-list",
  //   component: () =>
  //     import(
  //       "@/views/apps/anomalies-report/AnomaliesReport.vue"
  //     ),
  // },
   // *===============================================---*
  // *--------- SCOUTSUITE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/security-scan",
    name: "app-scoutsuite",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteTable.vue"),
  },
  {
    path: "/apps/security-scan/:id",
    name: "app-scoutsuite-list",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteList.vue"),
  },
  {
    path: "/apps/security-scan/:projectid/:id",
    name: "apps-scoutsuite-view",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteView.vue"),
  },
  {
    path: "/apps/security-scan/:projectid/:servicesid/:id",
    name: "apps-scoutsuite-role",
    component: () => import("@/views/apps/scoutsuite/ScoutsuiteRole.vue"),
  },
   // *===============================================---*
  // *--------- ChatGPT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chatbot",
    name: "apps-chatgpt",
    component: () =>
      import(
        "@/views/apps/chatgpt/ChatGpt.vue"
      ),
  },
   // *===============================================---*
  // *--------- Overview ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/overview",
    name: "apps-overview",
    component: () =>
      import(
        "@/views/apps/overview/Overview.vue"
      ),
  },
];
