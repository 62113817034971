import Vue from "vue";

Vue.filter('toCurrency', function (value, currency) {
    if (currency == 'USD') {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
        });
        return formatter.format(value);
    }

    if (currency == 'GBP') {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
        });
        return formatter.format(value);
    }
    if (currency == 'EUR') {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
        });
        return formatter.format(value);
    }
});