import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import IdleVue from "idle-vue";
import "@/filters/filter";

import i18n from "@/libs/i18n";
import firebase from "firebase/compat/app";
import router from "./router";
import store from "./store";
import App from "./App.vue";
// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
// import '@/libs/sweet-alerts'
import "@/libs/vue-select";
import "@/libs/tour";
import Hotjar from 'vue-hotjar'

// Axios Mock Adapter
// import '@/@fake-db/db'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

// BSV Plugin Registration
Vue.use (Hotjar, {
  id: '3425601' // Hotjar Site ID
})
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: process.env.VUE_APP_IDLE_TIME, // idle time is 30 min
  startAtIdle: false,
});
// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
// require('@/assets/scss/style.scss')

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  firebase,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
