<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    <app-loader :isLoading="isLoading" />
    <div>
      <b-modal hide-footer v-model="showModal" title="Session is going to expire">
        <div class="d-block text-center">
          <h4 class="mb-0">You have left this browser idle for 10 minutes.</h4>
        </div>
       <div>
          <b-button
            @click="hideModal()"
            variant="primary"
            class="float-right mt-1"
          >
            Continue
          </b-button>
          <b-button
            variant="primary"
            class="float-right mt-1 mr-1"
            @click="logout"
          >
            Logout
          </b-button>
       </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";
import AppLoader from "./@core/components/app-loader/AppLoader.vue";
import { BModal, BButton } from "bootstrap-vue";
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useWindowSize, useCssVar } from "@vueuse/core";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import axios from "@axios";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    BModal,
    BButton,
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ToastificationContent,
    ScrollToTop,
    AppLoader,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    isLoading() {
      return this.$store.state.appConfig.isLoading;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    "$store.state.idleVue.isIdle": {
      handler() {
        if (this.$store.state.idleVue.isIdle == true) {
          if (this.$route.path !== "/login") {
            this.showModal = true;
          } else {
            this.showModal = false;
          }
          if (this.showModal) {
            setTimeout(() => {
              this.logout(); //user will logout after 5 mins
            }, 300000);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    setTimerForLogout() {
      setTimeout("test", 9000);
    },
    hideModal() {
      this.showModal = false;
    },
    logout() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = localStorage.getItem("accessToken");

      axios
        .get(`/auth/logout`, {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
          params: { uid: userData.id },
        })
        .then(() => {
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
          localStorage.removeItem("notificationCount");
          localStorage.removeItem("notificationData");
          localStorage.removeItem("securityanalysisDatabaseNameIndex");
          localStorage.removeItem("securityanalysisData");
          // Remove userData from localStorage
          localStorage.removeItem("userData");
          localStorage.removeItem("regionCurrencyData");
          store.commit("app/USER_DATA", {});
          store.commit("app/CURRENCY_TYPE", "");
          // Reset ability
          this.$ability.update(initialAbility);

          // Redirect to login page
          this.$router.push({ name: "auth-login" });
          this.hideModal();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
};
</script>
