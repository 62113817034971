import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import apps from "./routes/apps";
import dashboard from "./routes/dashboard";
import notification from "./routes/notification";
// import uiElements from "./routes/ui-elements/index";
import pages from "./routes/pages";
// import others from "./routes/others";
import { useRouter } from "vue-router"; // import router
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard-usa" } },
    ...apps,
    ...dashboard,
    ...notification,
    ...pages,
    // ...uiElements,
    // ...others,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  const userData = getUserData();

  if (
    (to.path === "/apps/organizations/list" || to.path === "/apps/partner") &&
    userData.role_name === "ORG_ADMIN"
  ) {
    next({ name: "misc-not-authorized" });
  }

  if (
    (to.path === "/apps/organizations/list" ||
      to.path === "/apps/partner" ||
      to.path === "/apps/users/list") &&
    userData.role_name === "EMPLOYEE"
  ) {
    next({ name: "misc-not-authorized" });
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role_name : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
