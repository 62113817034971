import jwtDefaultConfig from "./jwtDefaultConfig";
import router from "@/router";
import { initialAbility } from "@/libs/acl/config";
import ability from "../../../libs/acl/ability";
import axios from "@axios";
import store from "@/store";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();
        config.headers = { "Content-type": "application/json" };
        config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        if (response.data.relogin == true) {
          const userData = JSON.parse(localStorage.getItem("userData"));
          const token = localStorage.getItem("accessToken");

          axios
            .get(`/auth/logout`, {
              headers: {
                Authorization: "Bearer " + token, //the token is a variable which holds the token
              },
              params: { uid: userData.id },
            })
            .then(() => {
              localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
              localStorage.removeItem(
                this.jwtConfig.storageRefreshTokenKeyName
              );
              localStorage.removeItem("notificationCount");
              localStorage.removeItem("notificationData");
              localStorage.removeItem("securityanalysisData");
              localStorage.removeItem("securityanalysisDatabaseNameIndex");
              // Remove userData from localStorage
              localStorage.removeItem("userData");
              localStorage.removeItem("regionCurrencyData");
              store.commit("app/USER_DATA", {});
              store.commit("app/CURRENCY_TYPE", "");
              // Reset ability
              ability.update(initialAbility);

              // Redirect to login page
              router.push({ name: "auth-login" });
              store.commit("appConfig/SPINNER_CONFIG", false);
            })
            .catch((error) => {
              console.log(error);
            });
        }

        if (response.status === 401) {
          store.commit("appConfig/SPINNER_CONFIG", false);

          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false;

              // Update accessToken in localStorage
              this.setToken(r.data.access_token);
              this.setRefreshToken(r.data.refresh_token);

              this.onAccessTokenFetched(r.data.accessToken);
            });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    const refreshParams = {
      refreshToken: this.getRefreshToken().split('"').join(""),
    };
    return this.axiosIns.post(`/auth/refresh_token`, refreshParams);
  }
}
